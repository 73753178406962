.total__total_text {
  color: rgb(29, 31, 83);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 700;
  line-height: 10.89px;
  text-align: right;
}

.total__total_text__alt {
  color: rgb(29, 31, 83);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 700;
  line-height: 10.89px;
  text-align: right;
  &.colored {
    color: #ec008c;
  }
}

.total__total_price {
  color: rgb(29, 31, 83);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 700;
  line-height: 10.89px;
  text-align: right;
  background: rgba(240, 238, 232, 1);
  padding: 0.5em;
  text-align: center;
  width: fit-content;
  align-self: flex-end;
}

.total__total_price__alt {
  color: rgb(29, 31, 83);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  line-height: 10.89px;
  text-align: right;
  background: transparent;
  padding: 0.5em;
  text-align: center;
  &.colored {
    color: #ec008c;
  }
}

.total__section__left {
  border-right: 1px dashed #96a8c2;
  padding-right: 1em;
}
