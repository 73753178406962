/* src/styles.css */
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap");

$zIndexLow: 0;
$zIndexMedium: 100;
$zIndexHigh: 200;
$zIndexHigher: 300;
$zIndexHighest: 999;

$financialSummaryTableHeadColor: #3f4877;
$financialSummaryTableTdColor: #dceff4;
$financialSummaryTableTdAltColor: #ffffff;
$financialSummaryTableFooterColor: #b2dde9;
$cuantoTxtColor: #1d1f53;

$fontFamily: "Open Sans", sans-serif;
$alef: "Alef", sans-serif;

body {
  &[data-company="cuanto"] {
    // Text color
    & li,
    .section-title,
    .chapter-title,
    td {
      color: $cuantoTxtColor;
    }

    // General table-heads & child-td's 
    table:not(.summary-table1, .summary-graph-table, .plan-table) tr:first-child {
          background: #1D1F53;
          & > td {
            color: #fff;
          }
    }

    // Odds
    .table-attache table tr td.even,
    .table-attache table tr td.even + td + td,
    .new-attache-tables table tr td.even,
    .new-attache-tables table tr td.even + td + td {
      background-color: #e4edf9 !important;
    }

    // Evens
    .table-attache table tr td:first-child,
    .new-attache-tables table tr td:first-child,
    .table-attache table tr td.odd + td + td,
    .new-attache-tables table tr td.odd + td + td {
      background-color: #f4f8fd;
    }

    // Mid column
    & .table-attache tr:nth-child(even):not(:first-child) td:nth-child(2),
    .new-attache-tables tr:nth-child(even):not(:first-child) td:nth-child(2) {
      background-color: #efeee9 !important;
    }

    .pre-title {
      color: #1d1f53;
    }

    & img.attache-icon {
      width: 21px;
    }
  }

  &:has(.loader) {
    overflow: hidden;
  }
}

.App {
  text-align: center;
  direction: ltr;
  background: whitesmoke;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  code {
    background: rgb(231, 231, 231);
    display: inline-block;
    padding: 0.25em;
    border-radius: 1em;
    direction: ltr;
  }

  &.cuanto {
    background-color: rgb(255 236 248 / 51%) !important;
    background-blend-mode: multiply;
  }
}

.file-drop {
  width: 500px;
  height: 500px;
  border: 2px dotted rgb(124, 124, 124);
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.18s all;
  &.dragged-over {
    transform: rotate(2deg) scale(1.05);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}

.file-drop-target {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 30px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// -----------------
@page {
  size: A4;
  margin: 0;
}

body {
  direction: rtl;
  &:has(.web-preview:not(.hidden)) {
    display: flex;
    flex-direction: row-reverse;
  }
}

#root {
  &:has(:not(.App)) {
    width: 210mm;
    margin: auto;
  }
  &:has(.web-preview:not(.hidden)) {
    width: calc(100% - 282px);
    margin: 0;
  }
  &:has(.web-preview.mobile) {
    width: 100%;
    margin: 0;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;700;800&display=swap");

.cover {
  background-color: whitesmoke;
  background-size: contain;
  min-height: 297mm;
  break-after: page;
  border: 1px solid grey;
  position: relative;

  .cover-overlay {
    background-image: url("../public/images/cover.jpg");
    &.cuanto {
      background-image: url("../public/images/cover_cuanto.jpg");
    }
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $zIndexHigher;

    .cover-title {
      color: white;
      width: 366px;
      font-size: xxx-large;
      padding: 60px 87px;
      line-height: 50px;
      font-weight: 600;
    }

    &.cuanto {
      .cover-title {
        height: 250px;
        padding: 4px 0 0 0;
        color: #1d2b53;
        width: 440px;
        font-size: 34px;
        display: flex;
        font-weight: 700;
        flex-direction: column;
        margin-right: -6px;
        text-align: center;
        font-family: $fontFamily;
        .family-name {
          font-size: 22px;
          font-weight: 400;
          font-family: $alef;
        }
      }
    }
  }

  .customer-details {
    color: white;
    font-weight: 600;
  }
  .customer-details-blue {
    color: #1d1f53;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
  }
  .customer-details,
  .customer-details-blue {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 5px;
  }
}

.logo {
  width: 90px;
  padding: 20px 0px;
  align-self: end;
  &.cover {
    display: none;
  }
}

.chapter,
.chapter-second-page {
  display: flex;
  flex-direction: column;
  min-height: 297mm;
  break-after: page;
  break-before: page;
  border: 1px solid grey;
  padding: 0 10mm;
  gap: 10px;

  &.financeSummary {
    background-color: #f4f4f2;
  }

  &.notFullPage {
    min-height: fit-content !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }

  .logo {
    width: 90px;
    padding: 20px 0px;
    align-self: end;
  }
  .chapter-title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $fontFamily;
    font-weight: 800;
    color: #43464c;
    font-size: 16pt;
    margin: 0px;
    img {
      width: 23px;
      margin-top: 2px;
    }
  }

  .chapter-image {
    margin: auto;
    display: flex;
    justify-content: center;
    img {
      width: 80%;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-self: center;
    font-size: 8pt;
    align-items: center;
    font-weight: 700;
    height: 70px;
    gap: 20px;
    color: #43464c;
    img {
      width: 100%;
    }
  }
}

.section {
  padding: 3px 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  &.general-section {
    ul {
      list-style-type: disclosure-closed;
    }

    .section-text {
      padding: 0px 10px;
    }
    li::marker {
      font-size: 9px;
    }
  }
  ul {
    list-style-type: square;
  }

  .section-text {
    font-family: $fontFamily;
    font-weight: 300;
    color: #43464c;
    font-size: 9pt;
    padding: 0px 30px;
    margin: -2px 0px;
  }
}

.logo-cover {
  display: none;
}

@media print {
  .hide-on-print {
    display: none !important;
  }

  .chapter,
  .cover {
    border: none;
    &.notFullPage {
      min-height: fit-content !important;
      page-break-after: avoid !important;
      page-break-before: avoid !important;
    }
  }

  .chapter {
    .chapter-title {
      padding-top: 55px;
    }

    &.notFullPage {
      .chapter-title {
        background-color: white;
        // padding-top: 55px;
      }
    }

    .table1 {
      page-break-inside: auto !important;
    }

    .chapter-image {
      padding-bottom: 30px;
      margin: auto;
    }
    &.notFullPage {
      // &:not(.firstNotFullPage) {
      //   margin-top: -100px;
      // }
      .chapter-title {
        padding-top: 55px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
  }

  .logo.chapter {
    display: none;
    width: 90px;
    padding: 20px 0px;
    align-self: end;
  }

  .logo.main {
    display: none;
  }

  .logo-cover {
    width: 90px;
    padding: 20px 0px;
    position: fixed;
    top: 8px;
    left: 45px;
    z-index: $zIndexLow;
    display: block;
  }
}

table,
tr,
td {
  //border: 1px solid;
  border-collapse: collapse;
}

.no-page-break {
  break-inside: avoid-page;
}

table {
  tr {
    min-height: 25px;
  }
  td {
    padding: 3px 5px;
  }
}

.table1 {
  padding: 15px 15px;
  &.no-padding {
    padding: 0;
  }
  table {
    width: 100%;
    th {
      color: white;
      background-color: $financialSummaryTableHeadColor;
    }
    tr {
      color: #43464c;
      font-weight: 400;
      font-size: 9pt;
      td:first-child {
        background-color: #e5ecee;
      }
      td:nth-child(2) {
        background-color: #f4f4f2;
        width: 10%;
        text-align: center;
      }
      td:nth-child(3) {
        background-color: #e5ecee;
        text-align: center;
      }
      // Make it better with odd even
      td:nth-child(4) {
        background-color: #f4f4f2;
        width: 10%;
        text-align: center;
      }
      td:nth-child(5) {
        background-color: #e5ecee;
        text-align: center;
      }
    }
  }

  &.electric {
    table {
      td:nth-child(3) {
        width: 15%;
        background-color: #e5ecee;
        text-align: center;
      }
    }
  }
}

.new-attache-tables {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

.table-attache,
.new-attache-tables {
  th {
    background-color: #3f4976;
    color: #ffffff;
    font-weight: bold;
  }

  padding: 15px 15px;
  &.no-padding {
    padding: 0;
  }
  table {
    width: 100%;
    th {
      color: white;
      background-color: $financialSummaryTableHeadColor;
    }
    tr {
      td {
        font-size: 12px;
      }
      td:first-child {
        width: 40%;
        background-color: #f0f4f5;
      }
      td.even,
      td.even + td + td {
        background-color: #e5ebed;
      }
      td.odd + td + td {
        background-color: #f0f4f5;
      }
      td:nth-child(2),
      td:nth-child(3) {
        background-color: #f4f4f2;
        width: 40%;
        text-align: center;
      }
      td:nth-child(3) {
        background-color: #e5ecee;
        width: 20%;
        text-align: center;
      }
    }
  }

  img.attache-icon {
    width: 16px;
    height: 16px;
  }

  tr:nth-child(odd):not(:first-child) td:nth-child(2) {
    background-color: #fafaf9 !important; /* Example style for odd rows */
  }

  tr:nth-child(even):not(:first-child) td:nth-child(2) {
    background-color: #f4f4f2 !important; /* Example style for even rows */
  }
}

.table2 {
  padding: 15px 15px;
  table {
    width: 100%;
    tr {
      text-align: center;
    }
    tr:first-child {
      color: white;
      background-color: #3f4877;
      font-weight: 700;
      font-size: 9pt;
      text-align: center;
    }
    tr:not(:first-child) {
      color: #43464c;
      font-weight: 400;
      font-size: 9pt;
      td:nth-child(odd) {
        background-color: #e4e9eb;
      }
      td:nth-child(even) {
        background-color: #f4f4f2;
      }
      td:first-child {
        background-color: #e5ecee;
      }
      td:nth-child(2) {
        width: 20%;
        text-align: center;
      }
    }
  }
}

.table4 {
  padding: 15px 15px;
  table {
    width: 100%;
    tr:first-child {
      color: white;
      background-color: #3f4877;
      font-weight: 700;
      font-size: 9pt;
      text-align: center;
    }
    tr:not(:first-child) {
      td {
        color: #43464c;
        font-weight: 400;
        font-size: 9pt;
        text-align: center;

        &:nth-child(odd) {
          background-color: #e4e9eb;
        }

        &:nth-child(even) {
          background-color: #f4f4f2;
        }
      }
    }
  }
}

.summary-table1 {
  width: 100%;
  caption {
    color: white;
    background-color: $financialSummaryTableHeadColor;
    font-weight: 700;
    font-size: 9pt;
    text-align: center;
    padding: 2px;
  }
  tbody {
    tr {
      padding: 2px;
      td {
        color: #43464c;
        font-weight: 400;
        font-size: 9pt;

        &:nth-child(1) {
          background-color: $financialSummaryTableTdColor;
          width: 50%;
        }

        &:nth-child(2) {
          background-color: $financialSummaryTableTdAltColor;
          text-align: center;
        }
      }
    }
  }
}

.summary-graph-table {
  caption {
    color: white;
    background-color: $financialSummaryTableHeadColor;
    font-weight: 700;
    font-size: 9pt;
    text-align: center;
    padding: 2px;
  }
  tbody {
    tr {
      background-color: #ffffff;
      td {
        color: #43464c;
        font-weight: 400;
        font-size: 9pt;

        &:nth-child(1) {
          background-color: $financialSummaryTableTdColor;
          width: 50%;
        }
        &:nth-child(2) {
          padding: 0;
        }
        &:nth-child(3) {
          padding-left: 8px;
        }
      }
    }
  }
  .graph-progress {
    background-color: dimgray;
    height: 22px;
    padding-left: 9px;
  }
}

.finance-summary-tables {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.graph-style-tables-section {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  table {
    width: 100%;
  }
  .construction-table,
  .project-steps-table {
    width: 100%;
  }
}

.MuiChartsLegend-root {
  direction: ltr !important;

  text {
    font-size: 12px !important;
  }
}

.table-col-count-3 {
  width: 540px;
  margin: 0 auto;
}

.web-preview.mobile .table-col-count-3,
.web-preview.mobile .table-col-count-8 {
  overflow-x: scroll;
  width: 100%;
}

.pre-title {
  margin-top: 70px;
  padding-right: 28px;
  margin-bottom: -42px;
}

.pie-chart {
  canvas {
    position: initial !important;
  }
}

.pieChart * {
  background-color: transparent;
}

.chapter.second_page {
  .section:first-child {
    padding-top: 3em;
  }
}

.mid-entry {
  font-weight: 700;
  margin-top: 1.1em;
  margin-bottom: 0.5em;
  ~ li {
    margin-right: 2em;
  }
}

.web-preview {
  position: absolute;
  left: 0;
  background: #fff;
  width: calc(100% - 283px);
  padding: 2em;
  box-sizing: border-box;
  &.mobile {
    position: initial;
    width: 100%;
    max-width: 100vw;
    overflow-x: scroll;
  }
  .cover {
    max-width: 788px;
    border: none;
  }
  .chapter {
    border: 0;
    padding-bottom: 2em;
    min-height: auto;
    max-width: 718px;
    &.financeSummary {
      margin-top: 2em;
      padding-top: 2em;
      padding-bottom: 14em;
      justify-content: center;
    }
  }
  .pre-title {
    margin-bottom: 0;
  }
  .logo.main {
    display: none;
  }
  .footer {
    display: none;
  }
  .chapter-end-filler.no-page-break.chapter-image {
    margin: 0;
    display: none;
  }
}

.active-web-sidebar-item {
  background: whitesmoke;
  border-radius: 1em;
}

.inserted-image {
  margin-top: 2em !important;
}

h4.section-title {
  margin-top: 0;
  margin-bottom: 0;
}

.grey-txt {
  color: #43464c;
}

.font-alef {
  font-family: $alef;
}

.tax-td {
  color: #1d1f53 !important;
  background-color: #9fc9eb !important;
  font-weight: 700 !important;
}

.price-meter-td {
  color: #fff !important;
  background-color: #a10261 !important;
  font-weight: 700 !important;
}

.general-comment {
  list-style-type: disclosure-closed !important;
  padding: 0px 10px !important;

  & li::marker {
    font-size: 9px;
  }
}

.basement-comment {
  list-style-type: disclosure-closed !important;
  margin: 0px -16px !important;
  line-height: 2.5;
}

li.basement-comment::marker {
    font-size: 9px;
}
